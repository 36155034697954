<template>
  <div class="pre-body pre-body-md">
    <div class="pre-pad"></div>
    <router-link class="mx-auto" :to="{ name: $routerItems.LANDING }"
      ><img class="pre-error-logo" src="/assets/images/logoGlitch.gif"
    /></router-link>
    <div class="pre-pad"></div>
    <div class="pre-headline centered">404</div>
    <p class="pre-subhead--copy text-uppercase text-center">NOT FOUND</p>
    <div class="pre-pad"></div>
    <router-link
      class="pre-subhead--copy text-uppercase text-center"
      :to="{ name: $routerItems.LANDING }"
    >
      Return To Home Page
    </router-link>
  </div>
</template>
<script>
export default {
  name: "404",
};
</script>

<style lang="scss" scoped>
.pre-error-logo {
  width: 248px;
}
</style>
